import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme'; // Importera temat
import { AuthProvider } from './auth/AuthProvider'; // Se till att importen är rätt
import ProtectedRoute from './auth/ProtectedRoute';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppBarWithDrawer from './components/layout/AppBarWithDrawer';
import Footer from './components/layout/Footer'; // Importera footern

import AddImage from './components/AddImage';
import ImageList from './components/ImageList';
import EditImage from './pages/EditImage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';

import EloBattle from './features/eloBattle/EloBattle';
import BattlePage from './features/eloBattle/BattlePage';
import LeaderboardAverage from './features/eloBattle/LeaderboardAverage';
import ImageEloHistoryPage from './features/eloBattle/ImageEloHistoryPage';




import EditImageFile from "./pages/EditImageFile";
import RateImagePage from './features/rating/RateImagePage'; // Se till att pathen är korrekt
import RatingLeaderboard from './features/rating/RatingLeaderboard'; // Se till att pathen är korrekt

import WordInputPage from './features/wordcloud/WordInputPage';
import ImageWordCloud from "./features/wordcloud/ImageWordCloud"; // 🔹 Importera komponenten
import WordInputStaticImagePage from './features/wordcloud/WordInputStaticImagePage';

import RatingChartPage from './features/rating/RatingChartPage';


//profilmenyn
import ProfilePage from './pages/ProfilePage';





const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>  {/* 🔹 Gör att hela sidan fyller hela höjden */}
            <AppBarWithDrawer />
            <main style={{ flexGrow: 1, padding: 0 }}>  {/* 🔹 Gör att huvudinnehållet expanderar */}
              <Routes>
                <Route path="/" element={<BattlePage />} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/profile" element={<ProfilePage />} />



                <Route path="/elo/battle" element={<EloBattle />} />
                <Route path="/elo/leaderboard-average" element={<LeaderboardAverage />} />
                <Route path="/elo/history" element={<ImageEloHistoryPage />} />
                
                

                <Route path="/rate" element={<RateImagePage />} />
                <Route path="/ratings" element={<RatingLeaderboard />} />
                <Route path="/word-input" element={<WordInputPage />} />
                <Route path="/wordcloud" element={<ImageWordCloud />} />

                <Route path="/ratings-chart" element={<RatingChartPage />} /> 

                <Route path="/" element={<BattlePage />} />


                <Route path="/" element={<BattlePage />} />
                <Route path="/add-image" element={<ProtectedRoute requiredRole="admin"><AddImage /></ProtectedRoute>} />
                <Route path="/list" element={<ProtectedRoute requiredRole="admin"><ImageList /></ProtectedRoute>} />
                <Route path="/edit/:id" element={<ProtectedRoute requiredRole="admin"><EditImage /></ProtectedRoute>} />
                <Route path="/edit-image-file" element={<ProtectedRoute requiredRole="admin"><EditImageFile /></ProtectedRoute>} />
                <Route path="/ord-static" element={<ProtectedRoute requiredRole="admin"><WordInputStaticImagePage /></ProtectedRoute>} />


              </Routes>
            </main>
            <Footer /> {/* ✅ Footern kommer alltid ligga längst ner */}
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};


export default App;