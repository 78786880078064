import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import supabase from "../../utils/supabaseClient";

const WordInputStaticImagePage = () => {
  const [images, setImages] = useState([]);
  const [selectedImageId, setSelectedImageId] = useState("");
  const [imageData, setImageData] = useState(null);
  const [word, setWord] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);

  // Hämta alla bilder till dropdown
  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      const { data, error } = await supabase.from("images").select("id, url, title").order("title");
      if (!error && data) {
        setImages(data);
        setSelectedImageId(data[0]?.id || "");
        setImageData(data[0] || null);
      } else {
        console.error("❌ Fel vid hämtning av bilder:", error);
      }
      setLoading(false);
    };
    fetchImages();
  }, []);

  // Uppdatera vald bilddata
  useEffect(() => {
    if (!selectedImageId) return;
    const img = images.find((img) => img.id === selectedImageId);
    setImageData(img);
    inputRef.current?.focus();
  }, [selectedImageId, images]);

  // Spara ord via funktion
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!word.trim() || !selectedImageId) return;

    const { error } = await supabase.rpc("insert_word_with_metadata", {
      p_image_id: selectedImageId,
      p_word: word
    });

    if (error) {
      console.error("❌ Fel vid insättning:", error);
      setMessage("Kunde inte spara ordet. Försök igen.");
    } else {
      setMessage("✅ Ordet har sparats!");
      setWord("");
      inputRef.current?.focus();
    }
  };

  return (
    <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Associera ord till vald bild
      </Typography>

      {/* Dropdown för att välja bild */}
      <Grid item xs={12} sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Välj en bild</InputLabel>
          <Select value={selectedImageId} onChange={(e) => setSelectedImageId(e.target.value)}>
            {images.map((img) => (
              <MenuItem key={img.id} value={img.id}>
                {img.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : imageData ? (
        <>
          {/* Visa vald bild */}
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box
              sx={{
                width: "100%",
                maxWidth: "640px",
                height: "480px",
                backgroundColor: "#f0f0f0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
                overflow: "hidden"
              }}
            >
              <img
                src={imageData.url}
                alt={imageData.title}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </Grid>

          {/* Ordinmatning */}
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
                  <TextField
                    value={word}
                    onChange={(e) => setWord(e.target.value)}
                    placeholder="Ange ett ord eller en fras"
                    maxLength={50}
                    required
                    inputRef={inputRef}
                    fullWidth
                  />
                  <Button type="submit" variant="contained">
                    Posta
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h6" color="error">
          Ingen bild tillgänglig.
        </Typography>
      )}

      {message && (
        <Typography sx={{ mt: 2 }} color={message.includes("✅") ? "success.main" : "error.main"}>
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default WordInputStaticImagePage;
