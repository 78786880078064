import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import supabase from "../utils/supabaseClient";

const EditImage = () => {
  const { id } = useParams(); // Hämta bildens ID från URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [businessOptions, setBusinessOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [title, setTitle] = useState("");
  const [isActive, setIsActive] = useState(false); // Ny state för is_active

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Hämta bilddata
      const { data: imageData, error: imageError } = await supabase
        .from("images")
        .select(`
          *,
          image_business (
            business (
              id, name
            )
          ),
          image_tags (
            tags (
              id, name
            )
          )
        `)
        .eq("id", id)
        .single();

      // Hämta affärsområden och taggar
      const { data: businessData } = await supabase.from("business").select("id, name");
      const { data: tagData } = await supabase.from("tags").select("id, name");

      if (imageError) {
        console.error("Error fetching image:", imageError);
        setLoading(false);
        return;
      }

      setImage(imageData);
      setTitle(imageData.title || "");
      setIsActive(imageData.is_active || false); // Sätt is_active från databasen
      setSelectedBusiness(imageData.image_business.map((b) => b.business));
      setSelectedTags(imageData.image_tags.map((t) => t.tags));
      setBusinessOptions(businessData || []);
      setTagOptions(tagData || []);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const handleSave = async () => {
    setLoading(true);

    // Uppdatera titel och is_active
    const { error: updateError } = await supabase
      .from("images")
      .update({ title, is_active: isActive })
      .eq("id", id);

    // Uppdatera affärsområden
    await supabase.from("image_business").delete().eq("image_id", id);
    await supabase.from("image_business").insert(
      selectedBusiness.map((b) => ({ image_id: id, business_id: b.id }))
    );

    // Uppdatera taggar
    await supabase.from("image_tags").delete().eq("image_id", id);
    await supabase.from("image_tags").insert(
      selectedTags.map((t) => ({ image_id: id, tag_id: t.id }))
    );

    if (updateError) {
      console.error("Error updating image:", updateError);
    } else {
      navigate("/");
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Redigera Bild
      </Typography>
      <TextField
        label="Titel"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Switch
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            color="primary"
          />
        }
        label="Aktiv"
      />
      <Autocomplete
        multiple
        options={businessOptions}
        getOptionLabel={(option) => option.name}
        value={selectedBusiness}
        onChange={(e, value) => setSelectedBusiness(value)}
        renderInput={(params) => <TextField {...params} label="Affärsområden" />}
        fullWidth
        margin="normal"
      />
      <Autocomplete
        multiple
        options={tagOptions}
        getOptionLabel={(option) => option.name}
        value={selectedTags}
        onChange={(e, value) => setSelectedTags(value)}
        renderInput={(params) => <TextField {...params} label="Taggar" />}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ mt: 2 }}
        fullWidth
      >
        Spara
      </Button>
    </Box>
  );
};

export default EditImage;