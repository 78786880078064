import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import supabase from '../utils/supabaseClient';

const MultiSelect = ({ label, table, selectedValues, setSelectedValues }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);

      try {
        const { data, error } = await supabase.from(table).select('*');
        if (error) {
          console.error(`Error fetching data from ${table}:`, error);
          setOptions([]);
        } else {
          setOptions(data || []);
        }
      } catch (err) {
        console.error(`Unexpected error fetching data from ${table}:`, err);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [table]);

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option.name || ''}
        value={selectedValues.map((id) => options.find((opt) => opt.id === id)).filter(Boolean)}
        onChange={(event, newValue) => {
          setSelectedValues(newValue.map((option) => option.id));
        }}
        loading={loading}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...restProps } = getTagProps({ index });
            return (
              <Chip
                key={option.id}
                label={option.name}
                {...restProps}
                onDelete={() =>
                  setSelectedValues((prev) =>
                    prev.filter((id) => id !== option.id)
                  )
                }
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={`Välj ${label.toLowerCase()}`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        noOptionsText={`Inga ${label.toLowerCase()} tillgängliga`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      {!loading && options.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          Ingen data tillgänglig för {label.toLowerCase()}.
        </Typography>
      )}
    </Box>
  );
};

export default MultiSelect;