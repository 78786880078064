import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { useAuthContext } from '../auth/AuthProvider';
import supabase from '../utils/supabaseClient';

const ProfilePage = () => {
  const { user } = useAuthContext();
  const [gender, setGender] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false); // 🔹 Kontroll för dialog

  useEffect(() => {
    if (!user) return;

    const fetchProfile = async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('gender, birth_date')
        .eq('user_id', user.id)
        .single();

      if (!error && data) {
        setGender(data.gender || '');
        setBirthDate(data.birth_date || '');
      }
    };

    fetchProfile();
  }, [user]);

  const handleSave = async () => {
    setLoading(true);
    setMessage('');

    const { error } = await supabase.rpc('update_profile_data', {
      p_gender: gender,
      p_birth_date: birthDate,
    });

    if (error) {
      console.error('❌ Fel vid uppdatering:', error);
      setMessage('Det gick inte att spara dina uppgifter.');
    } else {
      setMessage('✅ Uppgifterna har sparats!');
    }

    setLoading(false);
  };

  const confirmDeleteAccount = () => {
    setOpenDialog(true);
  };

  const handleDeleteAccount = async () => {
    setOpenDialog(false);
    setLoading(true);
    setMessage('');

    try {
      const { error } = await supabase.rpc('delete_own_account');
      if (error) throw error;

      setMessage("❌ Ditt konto har raderats.");
      await supabase.auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.error("Fel vid radering:", error);
      setMessage("Det gick inte att radera kontot.");
    } finally {
      setLoading(false);
    }
  };

  // 🔹 Dialog för bekräftelse
  const deleteDialog = (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Bekräfta radering</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Är du säker på att du vill radera ditt konto? Denna handling går inte att ångra.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">
          Avbryt
        </Button>
        <Button onClick={handleDeleteAccount} color="error" autoFocus>
          Radera konto
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {deleteDialog}

      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Min sida
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Genom att ange demografisk data hjälper du till att skapa mer intressanta analyser,
          som tack får du själv se demografisk data samt du får mer omfattande topplistor.
          Datan analyseras inte på individnivå utan endast aggregerat
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Kön"
            select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            fullWidth
          >
            <MenuItem value="female">Kvinna</MenuItem>
            <MenuItem value="male">Man</MenuItem>
            <MenuItem value="other">Annat</MenuItem>
            <MenuItem value="unknown">Vill inte ange</MenuItem>
          </TextField>

          <TextField
            label="Födelsedatum"
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Spara'}
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={confirmDeleteAccount}
          >
            Radera konto
          </Button>

          {message && (
            <Typography align="center" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ProfilePage;