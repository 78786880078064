import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../auth/AuthProvider';

const UserInfo = () => {
  const { user, signOut } = useAuthContext();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const handleLogout = () => {
    signOut();
    handleMenuClose();
  };

  return (
    <>
      <Tooltip title="Användare">
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          onClick={handleMenuOpen}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>

      <Menu
  anchorEl={anchorEl}
  open={open}
  onClose={handleMenuClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
>
  {user
    ? [
        <MenuItem key="profile" onClick={() => handleNavigate('/profile')}>Min sida</MenuItem>,
        <MenuItem key="logout" onClick={handleLogout}>Logga ut</MenuItem>,
      ]
    : [
        <MenuItem key="login" onClick={() => handleNavigate('/login')}>Logga in</MenuItem>,
        <MenuItem key="register" onClick={() => handleNavigate('/register')}>Registrera</MenuItem>,
      ]
  }
</Menu>
    </>
  );
};

export default UserInfo;