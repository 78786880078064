import React, { useState } from 'react';
import supabase from '../utils/supabaseClient';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
  MenuItem,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [birthDate, setBirthDate] = useState('');
 // const [location, setLocation] = useState(null);
  const [gender, setGender] = useState(''); // För kön
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchLocation = async () => {
    if (!navigator.geolocation) {
      console.warn("❌ Geolocation stöds inte av din webbläsare.");
      return "unknown"; // 👈 Returnera "unknown" istället för att avbryta
    }
  
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve(`${latitude},${longitude}`);
        },
        (error) => {
          console.warn("⚠️ Platsåtkomst nekad:", error.message);
          resolve("unknown"); // 👈 Returnera "unknown" om användaren nekar
        }
      );
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    try {
      let userLocation = "unknown"; 
      try {
        userLocation = await fetchLocation();
        console.log("📌 Hämtad plats:", userLocation);
      } catch (locationError) {
        console.warn("⚠️ Kunde inte hämta plats:", locationError.message);
      }
  
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });
  
      if (authError) {
        console.error("❌ Fel vid registrering:", authError.message);
        setErrorMessage('Fel vid registrering: ' + authError.message);
        setLoading(false);
        return;
      }
  
      const userId = authData.user?.id;
      if (!userId) {
        setErrorMessage('Kunde inte hämta användar-ID efter registrering.');
        setLoading(false);
        return;
      }
  
      console.log("✅ Användare skapad med ID:", userId);
  
      const { error: profileError } = await supabase.from('profiles').insert([
        {
          user_id: userId,
          birth_date: birthDate || null,
          location: userLocation,
          gender: gender || "unknown",
        },
      ]);
  
      if (profileError) {
        console.error("❌ Fel vid sparande av profildata:", profileError.message);
        setErrorMessage('Fel vid sparande av profildata: ' + profileError.message);
        setLoading(false);
        return;
      }
  
      console.log("✅ Profil sparad i databasen!");
  
      setSuccessMessage('Registrering lyckades! Kontrollera din e-post för att verifiera kontot.');
      setEmail('');
      setPassword('');
      setBirthDate('');
      setGender('');
  
    } catch (err) {
      console.error("❌ Ett oväntat fel inträffade:", err);
      setErrorMessage('Ett oväntat fel inträffade. Försök igen senare.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, maxWidth: 500, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom align="center">
        Registrera dig
      </Typography>

      <form onSubmit={handleRegister}>
        <Grid container spacing={3}>
          {/* Email */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
            />
          </Grid>

          {/* Password */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Lösenord"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Birth Date */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Födelsedatum"
              type="date"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              required
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label="Kön"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
              variant="outlined"
            >
              <MenuItem value="male">Man</MenuItem>
              <MenuItem value="female">Kvinna</MenuItem>
              <MenuItem value="other">Annat</MenuItem>
            </TextField>
          </Grid>

          {/* Error Message */}
          {errorMessage && (
            <Grid item xs={12}>
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            </Grid>
          )}

          {/* Success Message */}
          {successMessage && (
            <Grid item xs={12}>
              <Typography color="success" variant="body2">
                {successMessage}
              </Typography>
            </Grid>
          )}

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Registrerar...' : 'Registrera'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default RegisterPage;