import useAuth from '../../auth/useAuth'; // Se till att importvägen är korrekt

const useMenuItems = () => {
  const { user } = useAuth(); // Hämta inloggad användare

  // Standardmeny som alla kan se
  let menuItems = [
    { text: 'Duellera', path: '/' },
    { text: 'Tagga', path: '/word-input' },
    { text: 'Sätt betyg', path: '/rate' },
    { text: 'Topplista dueller', path: '/elo/leaderboard-average' },
    { text: 'Topplista betyg', path: '/ratings' },
    { text: 'Ordmoln', path: '/wordcloud' },

    { text: 'Beta-test', path: '', isDivider: true },
    { text: 'ELO Historik', path: '/elo/history' },
    { text: 'Betygsdiagram', path: '/ratings-chart' }
  ];

  // Lägg till admin-sektionen endast om användaren är admin
  if (user?.role === 'admin') {
    menuItems = [
      ...menuItems, // Behåll tidigare menyval
      { text: 'Admin', path: '', isDivider: true },
      { text: 'Lägg till bild', path: '/add-image' },
      { text: 'Lista bilder', path: '/list' },
      { text: 'Uppdatera bildfil', path: '/edit-image-file' },
      { text: 'Posta ord (statisk bild)', path: '/ord-static' }

    ];
  }

  return menuItems;
};

export default useMenuItems;