import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import supabase from "../utils/supabaseClient";

const EditImageFile = () => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [message, setMessage] = useState("");

  // 🔹 Hämta befintliga bilder från Supabase-bucket
  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      const { data, error } = await supabase.storage.from("images").list();
      if (error) {
        console.error("Fel vid hämtning av bilder:", error);
      } else {
        setImages(data);
      }
      setLoading(false);
    };

    fetchImages();
  }, []);

  // 🔹 Hantera filval
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewFile(file);
  };

  // 🔹 Ersätt bild i Supabase-bucket men behåll samma filnamn
  const handleUpdate = async () => {
    if (!selectedImage || !newFile) {
      setMessage("Välj en befintlig bild och en ny fil att ersätta med.");
      return;
    }

    setLoading(true);
    const filePath = selectedImage.name; // Behåller filnamnet

    // 🔸 Ta bort den befintliga bilden
    await supabase.storage.from("images").remove([filePath]);

    // 🔸 Ladda upp den nya bilden med samma namn
    const { error } = await supabase.storage.from("images").upload(filePath, newFile);

    if (error) {
      console.error("Fel vid uppdatering av bild:", error);
      setMessage("Ett fel uppstod vid uppladdning av bilden.");
    } else {
      setMessage("Bilden har uppdaterats!");
    }

    setLoading(false);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4, p: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Ersätt Bild i Supabase
      </Typography>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {/* Välj befintlig bild */}
          <Grid item xs={12}>
            <Typography variant="body1">Välj en befintlig bild:</Typography>
            <Select
              fullWidth
              value={selectedImage || ""}
              onChange={(e) => setSelectedImage(e.target.value)}
            >
              {images.map((img) => (
                <MenuItem key={img.name} value={img}>
                  {img.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Välj ny bild */}
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Välj ny bild
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            {newFile && <Typography mt={1}>Ny bild vald: {newFile.name}</Typography>}
          </Grid>

          {/* Uppdatera-knapp */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth onClick={handleUpdate}>
              Uppdatera bild
            </Button>
          </Grid>

          {/* Meddelanden */}
          {message && (
            <Grid item xs={12}>
              <Typography color={message.includes("uppdaterats") ? "primary" : "error"} mt={2}>
                {message}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default EditImageFile;