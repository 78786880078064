import React, { useState, useEffect } from 'react';
import supabase from '../../utils/supabaseClient';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import EloHistoryChart from './EloHistoryChart'; // Importera graf-komponenten

function ImageEloHistoryPage() {
  const [images, setImages] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImageId, setSelectedImageId] = useState('');

  // 🔹 Hämta alla bilder från Supabase
  useEffect(() => {
    async function fetchImages() {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('images')
          .select('id, title')
          .order('title', { ascending: true });

        if (error) {
          console.error('Fel vid hämtning av bildlista:', error);
        } else {
          setImages(data);
          if (data.length > 0) {
            setSelectedImageId(data[0].id);
          }
        }
      } finally {
        setLoading(false);
      }
    }

    fetchImages();
  }, []);

  // 🔹 Hämta ELO-historik från Supabase
  useEffect(() => {
    async function fetchEloHistory() {
      if (selectedImageId) {
        setLoading(true);
        try {
          const { data, error } = await supabase
            .from('elo_history_for_graph_v2')
            .select('elo_score, created_at')
            .eq('image_id', selectedImageId)
            .order('created_at', { ascending: true });

          if (error) {
            console.error('Fel vid hämtning av ELO-historik:', error);
          } else {
            setImageData({
              id: selectedImageId,
              eloHistory: data
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }

    fetchEloHistory();
  }, [selectedImageId]);

  // 🔹 Formatera data för Chart.js
  const generateChartData = () => {
    if (!imageData || !imageData.eloHistory || imageData.eloHistory.length === 0) {
      return null;
    }
  
    // 🔹 Använd datum som textetiketter
    const labels = imageData.eloHistory.map((entry) => 
      new Date(entry.created_at).toLocaleDateString('sv-SE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    );
    const data = imageData.eloHistory.map((entry) => entry.elo_score);
  
    return {
      labels,
      datasets: [
        {
          label: 'ELO Poäng',
          data,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0, // 🔹 Rak linje utan smoothness
          pointRadius: 4, // 🔹 Tydligare punkter
          pointHoverRadius: 6, // 🔹 Större punkter vid hover
          borderWidth: 2, // 🔹 Tydligare linje
          spanGaps: true
        },
      ],
    };
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" align="center">
        ELO Historik
      </Typography>

      {loading && (
        <Grid item xs={12} align="center">
          <CircularProgress />
        </Grid>
      )}

      <Select
        value={selectedImageId}
        onChange={(e) => setSelectedImageId(e.target.value)}
        fullWidth
        sx={{ mb: 4 }}
      >
        {images.map((image) => (
          <MenuItem key={image.id} value={image.id}>
            {image.title}
          </MenuItem>
        ))}
      </Select>

      <EloHistoryChart data={generateChartData()} />
    </Container>
  );
}

export default ImageEloHistoryPage;