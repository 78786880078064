import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ImageSelector = ({ images, selectedImageId, onSelectImage }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Välj en bild</InputLabel>
      <Select value={selectedImageId} onChange={(e) => onSelectImage(e.target.value)}>
        {images.map((image) => (
          <MenuItem key={image.id} value={image.id}>
            {image.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ImageSelector;
