import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid, Typography, Button, CircularProgress, Box } from '@mui/material';
import supabase from '../../utils/supabaseClient';

const RateImagePage = () => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const firstLoad = useRef(true); // ✅ Hindrar dubbelanrop vid första inladdningen

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false; // ✅ Stoppar ytterligare körningar
      fetchNewImage();
    }
  }, []);

  // 🔹 Hämta en ny bild som inte redan har betygsatts
  const [lastRatedImageId, setLastRatedImageId] = useState(null); // 🆕 Sparar senaste betygsatta bilden

  const fetchNewImage = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.rpc('get_image_to_rate');
  
      if (error || !data || data.length === 0) {
        console.error("❌ Kunde inte hämta bild att betygsätta:", error);
        setImageData(null);
      } else {
        setImageData(data[0]);
      }
    } catch (err) {
      console.error("❌ Ovväntat fel:", err);
    } finally {
      setLoading(false);
    }
  };


// 🔹 Hantera betygssättning och spara till databasen
const handleRating = async (rating) => {
  if (!imageData) {
    console.error("❌ Ingen bilddata tillgänglig.");
    return;
  }

  try {
    // 🔹 Anropa Supabase-funktion som hanterar hela insättningen
    const { error } = await supabase.rpc('insert_rating_with_metadata_secure', {
      p_image_id: imageData.id,
      p_rating: rating,
    });

    if (error) {
      console.error("❌ Kunde inte spara betyg:", error);
      return;
    }

    console.log(`✅ Betyg ${rating} sparat för bild ${imageData.id}`);

    // 🔄 Hämta ny bild
    setTimeout(fetchNewImage, 100);
  } catch (error) {
    console.error("❌ Fel vid betygssättning:", error);
  }
};

  return (
    <Container maxWidth="sm" sx={{ padding: 2 }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Betygsätt {imageData ? imageData.title : 'Bild'}
          </Typography>
        </Grid>

        {loading ? (
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : imageData ? (
          <>
            <Grid item xs={12} display="flex" justifyContent="center">
            <Box
  sx={{
    width: "100%",
    maxWidth: "640px",
    backgroundColor: loading ? "#f0f0f0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    overflow: "hidden",
    mx: "auto",
  }}
>
  <img
    src={imageData.url}
    alt={imageData.title}
    style={{ width: "100%", height: "auto", objectFit: "contain" }}
  />
</Box>
</Grid>

            <Grid item xs={12} container justifyContent="center" spacing={1}>
              {[1, 2, 3, 4, 5].map((rating) => (
                <Grid item key={rating}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRating(rating)}
                    size="large"
                  >
                    {rating}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Ingen ny bild tillgänglig.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default RateImagePage;