// src/components/Footer.js
import React from 'react';
import { Box } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#000',
        width: '100vw',           // Täcker hela sidbredden
        padding: '10px 0',
        textAlign: 'center',
        position: 'relative',     
        left: '50%',
        transform: 'translateX(-50%)', // Korrigerar margin från Container
      }}
    >
      <img
        src="/images/framework/frame-work-brand-o-meter-logo.svg"
        alt="Footer Logo"
        style={{ width: '100%', maxWidth: '200px' }} // Responsiv logotyp
      />
    </Box>
  );
}

export default Footer;