import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo'; // Importera UserInfo
import useAuth from '../../auth/useAuth'; // Hämta användarens info
import useMenuItems from './MenuItems'; // Hämta menyobjekt som en funktion

const AppBarWithDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth(); // Hämta inloggad användare
  const menuItems = useMenuItems(); // 🔹 Hämta menyobjekt från funktionen

  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
          
          {/* Menyikon (vänster) */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>

          {/* Logga (centrerad) */}
          <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
  <Box
    component="img"
    src="/images/framework/frame-work-brand-o-meter-logo.svg"
    alt="Logo"
    sx={{
      height: { xs: 20, sm: 25, md: 30 }, // 📱 Responsiv höjd
      width: 'auto',
    }}
  />
</Box>

          {/* Inloggningsknapp (höger) */}
          <Box sx={{ ml: 'auto' }}>
            <UserInfo />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer-meny */}
      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={() => setDrawerOpen(false)}>
          <List>
          {console.log("menuItems:", menuItems)}
            {menuItems.map((item, index) =>
              item.isDivider ? (
                <React.Fragment key={index}>
                  <Divider />
                  <ListItem>
                    <ListItemText 
                      primary={item.text} 
                      sx={{ textAlign: 'center', fontWeight: 'bold', color: 'gray' }} 
                    />
                  </ListItem>
                </React.Fragment>
              ) : (
                <ListItem component="button" key={item.text} onClick={() => navigate(item.path)}>
                  <ListItemText primary={item.text} />
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default AppBarWithDrawer;