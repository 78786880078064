import React, { useState } from 'react';
import supabase from '../utils/supabaseClient';
import { Grid, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 🔹 Ny state för inloggning
  const navigate = useNavigate(); // 🔹 För att navigera efter inloggning

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    console.log('Försöker logga in med:', { email, password });

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error('Fel vid inloggning:', error.message);
        setErrorMessage('Fel vid inloggning: ' + error.message);
      } else {
        console.log('Inloggning lyckades:', data);
        setIsLoggedIn(true); // ✅ Uppdatera status till inloggad
      }
    } catch (err) {
      console.error('Ett oväntat fel inträffade:', err);
      setErrorMessage('Ett oväntat fel inträffade. Försök igen senare.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });
  
      if (error) {
        console.error("❌ Google-inloggning misslyckades:", error.message);
      } else {
        console.log("✅ Inloggning initierad:", data);
      }
    } catch (err) {
      console.error("💥 Något gick fel utanför Supabase:", err);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, maxWidth: 500, margin: 'auto', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom align="center">
        Logga in
      </Typography>

      {/* 🔹 Visa detta om användaren är inloggad */}
      {isLoggedIn ? (
        <>
          <Typography variant="h5" color="success.main">
            ✅ Du är inloggad!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => navigate('/')} // 🔹 Navigera till startsidan
          >
            Fortsätt
          </Button>
        </>
      ) : (
        <form onSubmit={handleLogin}>
          <Grid container spacing={3}>
            {/* Email */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>

            {/* Password */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Lösenord"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>

            {/* Error Message */}
            {errorMessage && (
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            )}

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {loading ? 'Loggar in...' : 'Logga in'}
              </Button>
              <Button
  variant="outlined"
  fullWidth
  onClick={handleGoogleLogin}
  sx={{ mt: 2 }}
>
  Logga in med Google
</Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="body2">
                Har du inget konto?{' '}
                <Link to="/register" style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}>
                  Registrera dig
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default LoginPage;