import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseKey) {
  console.error('Supabase URL eller nyckel saknas:', {
    supabaseUrl,
    supabaseKey,
  });
  throw new Error('Supabase URL och nyckel måste vara definierade.');
}



const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;