import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../utils/supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          console.error("❌ Auth Error:", sessionError);
          setUser(null);
          setLoading(false);
          return;
        }

        if (sessionData?.session) {
          const { data: userData, error: userError } = await supabase.auth.getUser();
          if (userError) {
            console.error("❌ Error fetching user:", userError);
            setUser(null);
          } else {
            setUser(userData.user);
          }
        } else {
          setUser(null);
        }
      } catch (err) {
        console.error("❌ Fel vid hämtning av användare:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    // 🚀 Lyssna på autentiseringsändringar och hantera avregistrering korrekt
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
      setLoading(false);
    });

    return () => {
      authListener?.subscription?.unsubscribe(); // ✅ Korrekt avregistrering
    };
  }, []);

  const signOut = async () => {
    try {
      await supabase.auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("❌ Fel vid utloggning:", error);
    }
  };

  // 🚀 Logga bara när `user` faktiskt ändras
  useEffect(() => {
    setUser((prevUser) => {
      if (JSON.stringify(prevUser) !== JSON.stringify(user)) {
        console.log("🔍 AuthProvider - User ändrad:", user);
      }
      return user;
    });
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, loading, signOut, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('❌ useAuthContext måste användas inom <AuthProvider>. Kontrollera att AuthProvider omsluter din komponent.');
  }
  return context;
};