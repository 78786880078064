import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ImageTitleList = ({ images, onSelect }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  // 🔹 Responsiva kolumnbrytningar
  const isXs = useMediaQuery(theme.breakpoints.down('sm')); // <600px
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600-900px
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900-1200px

  const columns = isXs ? 1 : isSm ? 2 : isMd ? 3 : 4;

  // 🔹 Dela upp i kolumner, kolumnvis fyllning
  const itemsPerColumn = Math.ceil(images.length / columns);
  const columnData = Array.from({ length: columns }, (_, i) =>
    images.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
  );

  const handleClick = (id) => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 🔹 Scrolla upp direkt
  
    if (onSelect) {
      onSelect(id); // 🔹 Visa moln utan navigering
    } else {
      navigate(`/wordcloud?imageId=${id}`); // 🔹 Navigera till annan sida
    }
  };

  return (
    <Box sx={{ width: '100vw', backgroundColor: '#222222', py: 4 }}>
      <Box
        sx={{
          maxWidth: '1200px',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'center',
          gap: 4,
          px: 2,
          flexWrap: 'wrap',
        }}
      >
        {columnData.map((column, colIndex) => (
          <Box key={colIndex} sx={{ flex: 1, minWidth: 0 }}>
            {column.map((image) => (
              <Typography
                key={image.id}
                component={Link}
                onClick={() => handleClick(image.id)}
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  display: 'block',
                  mb: 1,
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {image.title}
              </Typography>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageTitleList;