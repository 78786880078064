import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const RatingChart = ({ ratingsData }) => {
  // 🔹 Validering av indata
  if (
    !ratingsData ||
    !ratingsData.ratings ||
    Object.keys(ratingsData.ratings).length === 0
  ) {
    return (
      <Typography variant="body1" align="center" sx={{ mt: 3, color: "gray" }}>
        Ingen data tillgänglig för denna bild.
      </Typography>
    );
  }

  // 🔹 Destructuring av data från backend
  const { ratings, total_ratings, average_rating } = ratingsData;

  // 🔹 Betygsskala 1–5 (renderas i fallande ordning)
  const ratingScale = [5, 4, 3, 2, 1];

  return (
    <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", mt: 3 }}>
      {/* 🔹 Betygsinformation */}
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Övergripande Betyg
      </Typography>
      <Typography variant="body1">
        <strong>Genomsnittsbetyg:</strong> {average_rating}
      </Typography>
      <Typography variant="body1">
        <strong>Totalt antal betyg:</strong> {total_ratings}
      </Typography>

      {/* 🔹 Rendera staplar för varje betyg */}
      {ratingScale.map((rating) => {
        const count = ratings[rating] || 0;
        const percentage =
          total_ratings > 0 ? Math.round((count / total_ratings) * 100) : 0;

        return (
          <Box key={rating} sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography sx={{ minWidth: 50 }}>{rating} stjärnor</Typography>
            <Box sx={{ flexGrow: 1, mx: 1 }}>
              <LinearProgress
                variant="determinate"
                value={percentage}
                sx={{
                  height: 8,
                  borderRadius: 5,
                  backgroundColor: "#eee",
                  "& .MuiLinearProgress-bar": { backgroundColor: "#c76b30" },
                }}
              />
            </Box>
            <Typography sx={{ minWidth: 40 }}>{percentage}%</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default RatingChart;