// 🔹 React och Material-UI komponenter
import { Container, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import EloBattle from './EloBattle';

const BattlePage = () => {
  // 🔹 Lägg till laddningsstatus och felhantering
 
  const [error, setError] = useState(null);

  // 🔹 Memorera komponenten för bättre prestanda
  const battleComponent = useMemo(() => <EloBattle />, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        // 🔹 Simulerar laddning (ersätt med riktig datahämtning vid behov)
        await new Promise((resolve) => setTimeout(resolve, 500));
        
      } catch (err) {
        setError('Kunde inte ladda ELO Battle. Försök igen senare.');
       
      }
    };

    loadData();
  }, []);

  if (error) {
    // 🔹 Felhantering
    return (
      <Container maxWidth="md" sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={{ xs: 'sm', md: 'md' }} sx={{ mt: 4 }}>
      {battleComponent}
    </Container>
  );
};

export default BattlePage;