import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../auth/useAuth';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();

  console.log("🔍 Kontroll av behörighet...");
  console.log("👤 Användare:", user);
  console.log("🔑 Krävd roll:", requiredRole);
  console.log("👤 Användarroll:", user?.role);

  if (loading) {
    console.log("⏳ Laddar användardata...");
    return <div>Laddar...</div>;
  }

  if (!user) {
    console.log("❌ Ingen användare inloggad, omdirigerar till /login");
    return <Navigate to="/login" />;
  }

  if (requiredRole && user.role !== requiredRole) {
    console.log("❌ Användaren har inte rätt behörighet, omdirigerar till /");
    return <Navigate to="/" />;
  }

  console.log("✅ Behörighet OK, visar innehåll.");
  return children;
};

export default ProtectedRoute;