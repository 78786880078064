import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import supabase from '../utils/supabaseClient';

const ImageList = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('images')
          .select(`
            *,
            image_business (
              business (
                name
              )
            ),
            image_tags (
              tags (
                name
              )
            )
          `);

        if (error) {
          console.error('Error fetching images:', error);
          setRows([]);
        } else {
          const formattedData = data.map((image) => ({
            id: image.id,
            title: image.title || 'Ingen titel',
            elo_score: image.current_elo !== undefined ? image.current_elo : 'N/A',
            games: image.total_games || 0,
            wins: image.wins || 0,
            losses: image.losses || 0,
            lastPlayed: image.last_game
              ? new Date(image.last_game).toLocaleDateString()
              : 'Aldrig spelad',
            totalWords: image.total_word_count || 0,
            createdAt: image.created_at
              ? new Date(image.created_at).toLocaleDateString()
              : 'Okänd',
            topScore: image.highest_elo !== undefined ? image.highest_elo : 'N/A',
            bottomScore: image.lowest_elo !== undefined ? image.lowest_elo : 'N/A',
            business: image.image_business
              ? image.image_business.map((b) => b.business?.name || 'Okänt affärsområde').join(', ')
              : 'Inga affärsområden',
            tags: image.image_tags
              ? image.image_tags.map((t) => t.tags?.name || 'Okänd tagg').join(', ')
              : 'Inga taggar',
            url: image.url || null,
          }));
          setRows(formattedData);
        }
      } catch (err) {
        console.error('Unexpected error fetching images:', err);
        setRows([]);
      }
      setLoading(false);
    };

    fetchImages();
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const imageToDelete = rows.find((row) => row.id === selectedImageId);
      if (!imageToDelete) {
        console.error('Bilden kunde inte hittas.');
        setLoading(false);
        return;
      }

      const imageUrl = imageToDelete?.url;
      console.log('Hämtad bild-URL:', imageUrl);

      if (!imageUrl) {
        console.error('Bildens URL saknas, kan inte ta bort från bucket.');
        setLoading(false);
        return;
      }

      const bucketFilePath = imageUrl.replace(
        `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/images/`,
        '' // Anpassa här om det finns en annan bas-URL
      );

      console.log('Generated bucketFilePath:', bucketFilePath);

      const { error: bucketError } = await supabase.storage
        .from('images')
        .remove([bucketFilePath]);

      if (bucketError) {
        console.error('Fel vid radering från bucket:', bucketError);
        setLoading(false);
        return;
      }

      const { error: businessError } = await supabase
        .from('image_business')
        .delete()
        .eq('image_id', selectedImageId);

      if (businessError) {
        console.error('Fel vid radering av relaterade affärsposter:', businessError);
        setLoading(false);
        return;
      }

      const { error: tagsError } = await supabase
        .from('image_tags')
        .delete()
        .eq('image_id', selectedImageId);

      if (tagsError) {
        console.error('Fel vid radering av relaterade taggar:', tagsError);
        setLoading(false);
        return;
      }

      const { error: imageError } = await supabase
        .from('images')
        .delete()
        .eq('id', selectedImageId);

      if (imageError) {
        console.error('Fel vid radering av bilden från databasen:', imageError);
        setLoading(false);
        return;
      }

      console.log('Bilden raderades från databasen:', selectedImageId);

      setRows((prevRows) => prevRows.filter((row) => row.id !== selectedImageId));
    } catch (error) {
      console.error('Ett oväntat fel inträffade vid radering:', error);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const handleOpenDialog = (id) => {
    setSelectedImageId(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedImageId(null);
  };

  const columns = [
    { field: 'title', headerName: 'Titel', width: 200 },
    { field: 'elo_score', headerName: 'ELO-poäng', width: 120 },
    { field: 'games', headerName: 'Matcher', width: 100 },
    { field: 'wins', headerName: 'Vinster', width: 100 },
    { field: 'losses', headerName: 'Förluster', width: 100 },
    { field: 'lastPlayed', headerName: 'Senast spelad', width: 150 },
    { field: 'totalWords', headerName: 'Totala ord', width: 120 },
    { field: 'topScore', headerName: 'Högsta ELO', width: 120 },
    { field: 'bottomScore', headerName: 'Lägsta ELO', width: 120 },
    { field: 'business', headerName: 'Affärsområden', width: 200 },
    { field: 'tags', headerName: 'Taggar', width: 200 },
    { field: 'url', headerName: 'Fullständig URL', width: 400 },
    { field: 'createdAt', headerName: 'Skapad', width: 150 },
    {
      field: 'edit',
      headerName: 'Redigera',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => navigate(`/edit/${params.row.id}`)}
        >
          Redigera
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Radera',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleOpenDialog(params.row.id)}
        >
          Radera
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%', marginTop: 4 }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : rows.length > 0 ? (
        <>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
          <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>Bekräfta radering</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Är du säker på att du vill radera denna bild? Denna åtgärd kan inte ångras.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Avbryt</Button>
              <Button onClick={handleDelete} color="error">
                Radera
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Typography variant="h6" align="center">
          Ingen data tillgänglig.
        </Typography>
      )}
    </Box>
  );
};

export default ImageList;