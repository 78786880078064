import { useEffect, useState } from 'react';
import supabase from '../utils/supabaseClient';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();

        if (error) {
          console.error('❌ Fel vid hämtning av session:', error);
        } else if (!session) {
          console.log('👤 Ingen användare inloggad, visar gästfunktioner.');
          setUser(null); 
        } else {
          console.log('✅ Hämtad användare:', session.user);

          // 🔹 Hämta rollen från profiles-tabellen
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('role')
            .eq('user_id', session.user.id)
            .single();

          if (profileError) {
            console.error('⚠️ Fel vid hämtning av användarroll:', profileError);
            setUser({ ...session.user, role: 'user' }); // Standardroll om fel uppstår
          } else {
            console.log('🔑 Användarroll från profiles:', profile.role);
            setUser({ ...session.user, role: profile.role });
          }
        }
      } catch (err) {
        console.error('⚠️ Oväntat fel vid hämtning av session:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    // 🔹 Lyssna på förändringar av session
    const { data: subscription } = supabase.auth.onAuthStateChange((event, session) => {
      if (!session) {
        console.log('👤 Användaren loggade ut, tillbaka till gäst.');
        setUser(null);
      } else {
        console.log('🔄 Användaren loggade in:', session.user);
        fetchUser(); // 🛠️ Hämta uppdaterad roll vid inloggning
      }
    });

    return () => {
      if (typeof subscription?.unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    };
  }, []);

  const signIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      console.error('❌ Fel vid inloggning:', error);
    }
    return { data, error };
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('❌ Fel vid utloggning:', error);
    }
  };

  return { user, loading, signIn, signOut };
};

export default useAuth;