// src/theme/theme.js eller src/theme.js beroende på var du placerar den
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
   
   
    typography: {
        fontFamily: [
          'Roboto',     // Första valda fonten
          'Arial',      // Om 'Roboto' inte är tillgänglig, använd 'Arial'
          'sans-serif', // Fallback till sans-serif
        ].join(','),
        h1: {
          fontSize: '3rem',   // Anpassa storleken för h1
          fontWeight: 700,      // Tjocklek
          lineHeight: 1.2,      // Radavstånd
          color: '#000000',     // Textfärg
        },
        h2: {
          fontSize: '2rem',
          fontWeight: 600,
          lineHeight: 1.3,
          color: '#000000',
        },
        h3: {
          fontSize: '1.75rem',
          fontWeight: 500,
          lineHeight: 1.4,
          color: '#000000',
        },
        h4: {
          fontSize: '1.5rem',
          fontWeight: 500,
          color: '#000000',
        },
        h5: {
          fontSize: '1.25rem',
          fontWeight: 400,
          color: '#000000',
        },
        h6: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#000000',
        },
        body1: {
          fontSize: '1rem',
        },
        body2: {
          fontSize: '0.875rem',
        },
      },
   /*Sparat det gamla färgerna för att se originaltemat, sen gjorde jag allt svart i min egen style */

   /* typography: {
        fontFamily: [
          'Roboto',     // Första valda fonten
          'Arial',      // Om 'Roboto' inte är tillgänglig, använd 'Arial'
          'sans-serif', // Fallback till sans-serif
        ].join(','),
        h1: {
          fontSize: '2.5rem',   // Anpassa storleken för h1
          fontWeight: 700,      // Tjocklek
          lineHeight: 1.2,      // Radavstånd
          color: '#333333',     // Textfärg
        },
        h2: {
          fontSize: '2rem',
          fontWeight: 600,
          lineHeight: 1.3,
          color: '#444444',
        },
        h3: {
          fontSize: '1.75rem',
          fontWeight: 500,
          lineHeight: 1.4,
          color: '#555555',
        },
        h4: {
          fontSize: '1.5rem',
          fontWeight: 500,
          color: '#666666',
        },
        h5: {
          fontSize: '1.25rem',
          fontWeight: 400,
          color: '#777777',
        },
        h6: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#888888',
        },
        body1: {
          fontSize: '1rem',
        },
        body2: {
          fontSize: '0.875rem',
        },
      },

*/
    
palette: {
  text: {
    primary: '#000000', // Gör texten vit så den syns på svart AppBar
    secondary: '#cccccc', // Lite gråare sekundär text
  },

  primary: {
    main: '#000000', // 🔥 Nu är AppBar svart!
  },
  secondary: {
    main: '#f50057',
  },
},
  // Andra globala justeringar
});

export default theme;