import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, CircularProgress, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import supabase from '../../utils/supabaseClient';
import ImageTitleList from '../../components/shared/ImageTitleList';

// Funktion för att räkna antal ord och deras frekvens
function countWords(words) {
  return words.reduce((countMap, word) => {
    countMap[word.word] = (countMap[word.word] || 0) + word.frequency;
    return countMap;
  }, {});
}

// Ordmolnskomponent
function WordCloud({ words }) {
  const wordCounts = countWords(words);
  const maxCount = Math.max(...Object.values(wordCounts), 1); // Säkerställ att vi inte delar på 0

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
      {Object.entries(wordCounts).map(([word, count]) => (
        <span
          key={word}
          style={{
            fontSize: `${((count / maxCount) * 5.8) + 0.6}em`,
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          {word}
        </span>
      ))}
    </div>
  );
}

// Huvudkomponenten
function ImageWordCloud() {
  const [images, setImages] = useState([]);
  const [selectedImageId, setSelectedImageId] = useState('');
  const [imageWords, setImageWords] = useState([]);
  const [loading, setLoading] = useState(true);

  // Hämta alla bilder från Supabase
  const fetchAllImages = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
  .from('images')
  .select('id, title, url')
  .order('title', { ascending: true }); // ✅ Sorterar bilder i bokstavsordning

      if (error) throw error;
      setImages(data);
    } catch (error) {
      console.error('Fel vid hämtning av bilder:', error);
    } finally {
      setLoading(false);
    }
  };

  // Hämta ord kopplade till vald bild
  const fetchImageWords = async (imageId) => {
    if (!imageId) return;
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('word_statistics')  // 🔹 Byt ut 'words' mot din vy!
        .select('word, frequency')
        .eq('image_id', imageId);  // 🔹 Filtrerar orden baserat på vald bild
  
      if (error) throw error;
      setImageWords(data);

          // 🔹 Logga varje ord och dess frekvens
    console.log(`Ordmoln för bild ${imageId}:`, data);
    
    } catch (error) {
      console.error('❌ Fel vid hämtning av ord från vyn:', error);
    } finally {
      setLoading(false);
    }
  };

  // Kör vid sidans start
  useEffect(() => {
    fetchAllImages();
  }, []);

  // Körs när en ny bild väljs
  useEffect(() => {
    if (selectedImageId) fetchImageWords(selectedImageId);
  }, [selectedImageId]);

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {/* Titel */}
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {selectedImageId
                ? `Ordmoln för ${images.find((img) => img.id === selectedImageId)?.title}`
                : 'Välj en bild'}
            </Typography>
          </Grid>
  
          {/* Dropdown */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Välj en bild</InputLabel>
              <Select value={selectedImageId} onChange={(e) => setSelectedImageId(e.target.value)}>
                {images.map((image) => (
                  <MenuItem key={image.id} value={image.id}>
                    {image.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
  
          {loading && (
            <Grid item xs={12} align="center">
              <CircularProgress />
            </Grid>
          )}
  
          {selectedImageId && (
            <>
              {/* Bild */}
              <Grid item xs={12}>
                <img
                  src={images.find((img) => img.id === selectedImageId)?.url}
                  alt={selectedImageId}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              </Grid>
  
              {/* Ordmoln */}
              <Grid item xs={12}>
                {imageWords.length > 0 ? <WordCloud words={imageWords} /> : 'Inga ord tillgängliga.'}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
  
      {/* 🔹 Lägg till komponent längst ned – utanför Container */}
      <ImageTitleList images={images} onSelect={(id) => setSelectedImageId(id)} />
    </>
  );
}

export default ImageWordCloud;