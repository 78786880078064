import React, { useEffect, useState, useRef } from "react";
import { Container, Grid, TextField, Button, Typography, CircularProgress, Box } from "@mui/material";
import supabase from "../../utils/supabaseClient";

const WordInputPage = () => {
  const [imageData, setImageData] = useState(null);
  const [word, setWord] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false); // 🔹 Förhindra dubbelanrop
  const inputRef = useRef(null); // 🔹 Fokusera input efter inmatning
  const firstLoad = useRef(true); // 🔹 Förhindra dubbelanrop vid första inläsningen
  const [lastSkippedImageId, setLastSkippedImageId] = useState(null); // 🔹 Lagrar senaste skippade bilden

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false; // ✅ Hindrar ytterligare körningar vid första inläsningen
      fetchNewImage();
    }
  }, []);

  // 🔹 Hämta en ny bild från databasen
  const fetchNewImage = async () => {
    if (isFetching) return; // ✅ Förhindra parallella anrop
    setIsFetching(true);
    setLoading(true);
    console.log("🔄 Hämtar en ny bild...");
  
    try {
      let query = supabase
        .from("images")
        .select("id, url, title")
        .order("last_word", { ascending: true, nullsFirst: true }) // 📌 Prioritera bilder som saknar last_word
        .limit(1);
  
      if (lastSkippedImageId) {
        query = query.neq("id", lastSkippedImageId); // 🆕 Exkludera senast skippade bild
      }
  
      const { data, error } = await query;
  
      if (error) {
        console.error("❌ Fel vid hämtning av bild:", error);
        return;
      }
  
      if (!data || data.length === 0) {
        console.warn("⚠️ Ingen bild hittades. Kontrollera att databasen innehåller bilder.");
        return;
      }
  
      console.log("🔍 Hämtad bild-data:", data[0]);
      setImageData(data[0]);
  
    } catch (error) {
      console.error("❌ Ett oväntat fel inträffade vid hämtning av bilden:", error);
    } finally {
      setLoading(false);
      setIsFetching(false); // ✅ Avblockera anrop
    }
  };

  useEffect(() => {
    if (imageData && inputRef.current) {
      inputRef.current.focus(); // 🔹 Sätt fokus på inputfältet när ny bild laddas
    }
  }, [imageData]);


  // 🔹 Hantera inmatning och sparande av ord
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!word.trim()) return;
  
    try {
      // 🔹 Anropa Supabase-funktion – skickar INTE med user_id längre
      const { error } = await supabase.rpc('insert_word_with_metadata', {
        p_image_id: imageData.id,
        p_word: word,
      });
  
      if (error) {
        console.error("❌ Fel vid insättning:", error);
        setMessage("Kunde inte spara ordet. Försök igen.");
        return;
      }
  
      setMessage("✅ Ordet har sparats!");
      setWord(""); // Återställ inputfält
      inputRef.current?.focus(); // 🔹 Sätt fokus på input-fältet
      fetchNewImage(); // 🔄 Hämta ny bild
    } catch (error) {
      console.error("❌ Fel vid sparande av ord:", error);
      setMessage("Kunde inte spara ordet. Försök igen.");
    }
  };

  // 🔹 Hantera Skip-knappen
  const handleSkip = async () => {
    if (!imageData) return;
  
    console.log("⏭️ Bild hoppades över:", imageData.id);
    setMessage("⚠️ Bild hoppades över.");
    setLastSkippedImageId(imageData.id); // 🆕 Spara skippad bilds ID
    fetchNewImage(); // 🔄 Hämta ny bild
  };

  return (
<Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
  {/* Titel */}
  <Typography variant="h4" gutterBottom>
  {imageData ? imageData.title : "Associera ord till bilder"}
</Typography>

  {/* Visa bild eller laddningsindikator */}
  {loading ? (
    <CircularProgress />
  ) : imageData ? (
    <>
      {/* Bild-container: Behåller stor bredd */}
      <Grid item xs={12} display="flex" justifyContent="center">
      <Box
  sx={{
    width: "100%",
    maxWidth: "640px",
    backgroundColor: loading ? "#f0f0f0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    overflow: "hidden",
    mx: "auto", // 🔹 centrera på små skärmar
  }}
>
  {loading ? (
    <CircularProgress />
  ) : (
    <img
      src={imageData.url}
      alt={imageData.title}
      style={{ width: "100%", height: "auto", objectFit: "contain" }}
    />
  )}
</Box>
</Grid>

    

      {/* Ordinmatning - Uppdaterad layout */}
      <Grid container justifyContent="center">
        <Grid item xs={12} >
          <form onSubmit={handleSubmit}>
            <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: "sm", // 🔹 Gör layouten smalare och mer centrerad
        mx: "auto", // 🔹 Centrerar layouten
      }}
    >
              <TextField
                type="text"
                value={word}
                onChange={(e) => setWord(e.target.value)}
                placeholder="Ange ett ord eller en fras"
                maxLength={50}
                required
                inputRef={inputRef}
                fullWidth
                variant="outlined"
              />
              <Button type="submit" variant="contained" color="primary">
                Posta
              </Button>
              <Button variant="text" color="primary" onClick={handleSkip}>
                Skip
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>

        {/* Hjälptext ovanför inmatningsfältet */}
        <Grid container justifyContent="center">
        <Grid item xs={12} >
        <Typography
  variant="h6"
  color="#999"
  align="center"
  sx={{ mt: 3 }} // 🔹 Lägg till lite luft ovanför hjälptexten
>
  <b>Skriv det första du tänker när du ser varumärket!</b> <br />
  (Det finns inget rätt eller fel, men bara ett ord eller en kort fras tack)
</Typography>
        </Grid>
      </Grid>



    </>
  ) : (
    <Typography variant="h6" color="error">
      Ingen bild tillgänglig.
    </Typography>
  )}

  {/* Meddelande om lyckad/skeptisk sparning */}
  {message.includes("❌") && (
  <Typography variant="body1" sx={{ mt: 2, color: "error.main" }}>
    {message}
  </Typography>
)}
</Container>
  );
};

export default WordInputPage;