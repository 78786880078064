import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const EloHistoryChart = ({ data }) => {
  if (!data || data.labels.length === 0 || data.datasets[0].data.length === 0) {
    return (
      <p style={{ textAlign: 'center', color: 'gray' }}>
        Ingen ELO-historik tillgänglig.
      </p>
    );
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // ⬅️ Nödvändigt för att Box-höjden ska styra
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'ELO Historik per Datum',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Datum',
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: 'ELO Poäng',
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', height: { xs: 300, sm: 400, md: 500 } }}>
      <Line data={data} options={options} />
    </Box>
  );
};

export default EloHistoryChart;